import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./common/axios"

// 引入ElementUI组件
import { Dialog, Loading } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Dialog, Loading)
Vue.use(Loading.directive);  //  指令方式(v-loading)的话这两行都得有

Vue.config.productionTip = false

Vue.prototype.$xingheDongyuURL = process.env.VUE_APP_XINGHE_DONGYU_URL

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
