import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'

Vue.use(VueRouter)

let routerConfig = {
  mode: 'history',
  base: process.env.BASE_URL,
  routes
}

const router = new VueRouter(routerConfig)

export default router
