import Vue from 'vue'
import axios from 'axios'
import api from "./api"
import qs from 'qs'

// // 网站地址
// axios.defaults.webURL = process.env.webURL;
// 接口请求地址
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL


axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

axios.defaults.timeout = 200000;

// 添加请求拦截器(axios请求前)
axios.interceptors.request.use(config => {
  // console.log(store.state);
  let token = localStorage.getItem('token');
  if (token) {
    config.headers['token'] = token;
  }
  if (config.method === 'get') { //如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2 
    config.paramsSerializer = function (params) { return qs.stringify(params, { arrayFormat: 'repeat' }) }
  }
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器(axios请求后)
axios.interceptors.response.use(response => {
  // 对响应数据做点什么
  if (response.data.errcode == 100 || response.data.errmsg == "数据不存在") {
    location.href = '/error404'
    return false
  }
  if (response.data.meta != null && response.data.meta.state == 401) {
    location.href = '/login'
    return false
  }
  // if (response.data.meta != null && response.data.meta.state == 400) {
  //   location.href = '/grass/list'
  // }
  // if (response.data.meta != null && response.data.meta.state == 40001) {
  //   location.href = '/grass/list'
  // }
  if (response.data.meta != null) {
    return response.data
  } else {
    return response
  }
}, error => {
  // 对响应错误做点什么
  return Promise.reject(error);
});
const postData = (url, data, config = {}) => {
  // return axios.post(api[url], qs.stringify(data));
  return axios.post(api[url], data, config);
}
//  /movie/in_theaters
const getData = (url, params) => {
  return axios.get(api[url], {
    params
  });
}
const postUrl = (url, path, data, config = {}) => {
  // return axios.post(api[url], qs.stringify(data));
  return axios.post(api[url] + path, data, config).catch(function (error) {
  });
}
const getUrl = (url, path, params) => {
  return axios.get(api[url] + path, {
    params
  });
}
Vue.prototype.$postData = postData;
Vue.prototype.$postUrl = postUrl;
Vue.prototype.$getData = getData;
Vue.prototype.$getUrl = getUrl;
Vue.prototype.$axios = axios;

export default {
  postData, getData, postUrl, getUrl, axios
};
