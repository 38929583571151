<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import "../src/assets/css/reset.scss";
import "../src/assets/css/common.scss";
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss">
</style>
