
const routes = [
    {
        path: '/',
        component: () => import('../views/index.vue'),
    },
    {
        name: "Error404",
        path: '/error404',
        component: () => import('../views/404.vue')
    },
    {
        path: '*',
        component: () => import(/* webpackChunkName: "Error404" */ '../views/404.vue')
    }
]

export default routes